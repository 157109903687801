.cycle h3 {
	letter-spacing: 0.5em;
	text-transform: uppercase;
}

.cycle1 {
    background-image: url('../img/panorama_lac_de_Balme_Rousse.jpg');
    height: 300px;
    cursor: move;
}

.cycle2 {
    background-image: url('../img/panorama_Plan_des_Cavalles.jpg');
    height: 300px;
    cursor: move;
}

.cycle3 {
    background-image: url('../img/panorama_pic_etendard.jpg');
    height: 300px;
    cursor: move;
}

.cycle4 {
    background-image: url('../img/panorama_pic_bayle.jpg');
    height: 300px;
    cursor: move;
}

.panorama {
    padding: 0;
}

.layer {
    background: linear-gradient(to bottom,#000 0%,rgba(0,0,0,.79) 50%,rgba(255,255,255,.1%) 75%);
    opacity: .5;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    z-index: 2;
    backface-visibility: hidden;
}

.card-padding {
    padding: 15px;
}

.card-border {
    border: solid 2px grey;
    border-radius: 8px;
}

#carousel-refuge-pictures,#carousel-hikes-pictures img {
    object-fit: cover;
    width: 100%;
    max-height: 228px;
    @media (min-width: 992px) {
        max-height: 855px;
    }
}

.carousel-in-box {
    height: 200px;
    @media (min-width: 992px) {
        height: 400px;
    }
}

.carousel-inner.carousel-center {
    height: 100%;
    .item{
        height: 100%;
        img {
            height: 100%;
        }
    }
}

.img-menus {
    width: 100%;
    @media (min-width: 992px) {
        width: 25%;
    }
}

@media (min-width: 800px) { 
    .two-cols {
        display: flex;
        align-items: center;
    }
    .two-cols > * {
        width: 50%;
    }
}
